import _ from 'lodash';
import { routesList as getRoutesList } from '../constants/routesList';

const addToRouteList = (navigation = {}, routeList = [], routeItem) => {
  const newPaths = _.get(navigation, 'newPaths', []);
  const navigationPath = _.get(navigation, 'path', '');
  const routeName = _.get(routeItem, 'name', '');
  const routePath = _.get(routeItem, 'path', '');
  let isNavigationPathAdded = false; // flag to prevent duplicating route item that is caused by having the same path string in navigation.newPaths and navigation.path
  if (routeName?.toLowerCase() === 'videos' && newPaths) {
    // only allow abbreviated competition code for Videos route
    newPaths.forEach((path) => {
      let newRouteItem = { ...routeItem };
      newRouteItem.path = `/${path}${routePath}`;
      routeList.push(newRouteItem);
      if (path === navigationPath) {
        isNavigationPathAdded = true;
      }
    });
  }
  if (!isNavigationPathAdded) {
    let newRouteItem = { ...routeItem };
    newRouteItem.path = `/${navigationPath}${routePath}`;
    routeList.push(newRouteItem);
  }
};

export function getRouteList(navigations, props) {
  let retList = [];

  getRoutesList(props).forEach((item) => {
    if (!item.inSection) {
      // path is not in section
      retList.push(item);
    } else {
      // this path is within a section, add section path prefix based on backend navigation
      if (Array.isArray(navigations)) {
        navigations.forEach((nav) => {
          if (!nav.landingPage) {
            let newItem = { ...item };
            addToRouteList(nav, retList, newItem);
          }
        });
      }
    }
  });

  retList = retList.map((route) => {
    route.key = getRouteKey(route);
    return route;
  });

  return retList;
}

function getRouteKey(route) {
  const { name = '', path = '' } = route || {};
  const pathStr = path?.replace(/[^a-zA-Z0-9 ]/g, '-') || '';
  return `${name?.toLowerCase()}_${pathStr}`;
}
