import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function paymentSessionsReducer(state, action) {
  const initPaymentSessionsState = initialState?.paymentSessions || {};
  state = typeof state === 'undefined' ? initPaymentSessionsState : state;

  switch (action.type) {
    case types.SET_PAYMENT_SESSIONS: {
      const { state: paymentSessionsState } = action;
      const returnObj = {
        ...state,
        ...paymentSessionsState,
      };
      return returnObj;
    }
    default: {
      return state;
    }
  }
}
